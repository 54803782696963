import * as THREE from 'three';

const palette = [
    //color way
    0xffa071, // atomic tangerine 
    0xffb480, // macaroni and cheese
    0xfec98f, // mellow apricot
    0xfedd9e, // navajo white
    0xfdf1ad, // medium champagne
    0x0f0c05, // smoky black (yellow hue)
    0xB83D00, // rust
    0xCC5500, //burnt orange
    0xB75F01, //alloy orange
    0x140C08, //smoky black(orange hue)
];


const scene = new THREE.Scene();

const fov = 75;
const aspect = window.innerWidth / window.innerHeight;
const near = 0.1;
const far = 100;
const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

const renderer = new THREE.WebGL1Renderer({
    canvas: document.querySelector('#landingBG'),
});

renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
camera.position.z = 5;


    const color = 0xFFFFFF;
    const intensity = 0.2;
    const light = new THREE.DirectionalLight(color, intensity);
    const ambientLight = new THREE.AmbientLight(0xFFFFFF, 0.8);
    ambientLight.position.set(5,5,5);
    light.position.set(-1, 2, 4);
    scene.add(ambientLight, light);


renderer.render(scene, camera);
renderer.setClearColor(palette[5]);

const boxWidth = 3;
const boxHeight = 3;
const boxDepth = 3;
const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);

function makeInstance(geometry, color, x, y, z) {
    //const y = -4;
    //const z = 0;
    const material = new THREE.MeshPhongMaterial({
        color
    });

    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    cube.position.x = x;
    cube.position.y = y;
    cube.position.z = z;


    return cube;
}


const cubes = [
    makeInstance(geometry, palette[6], -6, -4, 0),
    makeInstance(geometry, palette[6], -4, -4, 0),
    makeInstance(geometry, palette[7], -2, -3, -0.5),
    makeInstance(geometry, palette[7], 0, -3, -0.5),
    makeInstance(geometry, palette[7], 2, -3, -0.5),
    makeInstance(geometry, palette[8], 4, -4, 0),
    makeInstance(geometry, palette[8], 6, -4, 0),

];


    //Helpers
    // const lightHelper = new THREE.PointLightHelper(light);
    // const gridHelper = new THREE.GridHelper(200, 50);
    // scene.add(lightHelper, gridHelper);


function render(time) {
    time *= 0.0005; // convert time to seconds

    cubes.forEach((cube, ndx) => {
        const speed = 1 + ndx * .1;
        const rot = time * speed;
        cube.rotation.x = rot;
        cube.rotation.y = rot;
    });

    renderer.render(scene, camera);

    requestAnimationFrame(render);
}

requestAnimationFrame(render);